import useAxios from "axios-hooks";
import React, { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Box, Flex, Text, useColorModeValue, Select } from "@chakra-ui/react";
import * as echarts from "echarts";

// Custom components
import Card from "components/card/Card.js";
import logger from "infra/logger";
import { monthlyDowntimeBar } from "variables/charts";

const MonthlyDowntimeReport = (props) => {
  const { ...rest } = props;
  const chartRef = useRef(null);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [chartOptions, setChartOptions] = useState(monthlyDowntimeBar);
  const [yearList, setYearList] = useState([]);
  const [isInitial, setIsInitial] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=monthly_downtime_report_trend&cp=${
        props?.chargePointCode == "all" ? "" : props?.chargePointCode
      }&year=${selectedYear}`,
    },
    { manual: true }
  );

  const getMonthlyDowntime = async () => {
    try {
      const response = await execute();
      const monthlyData = response?.data?.data;
      const legendData = monthlyData?.map((ele) => {
        return ele.station_name;
      });
      const seriesData = monthlyData?.map((item) => {
        const itemSessions = item.total_downtime.map((ele) => {
          return (ele.total_downtime / 60).toFixed(3);
        });
        return {
          name: item.station_name,
          data: itemSessions,
          type: "bar",
          smooth: true,
          barWidth: 20,
          stack: "stacked",
        };
      });
      const adjustedBottomPadding = Math.max(
        80,
        Math.ceil(legendData.length / 5) * 30
      );
      setChartOptions((prev) => {
        // Check if the seriesData[0] name exists in the updated array
        const exists = prev.series.some(
          (element) => element.name === seriesData[0]?.name
        );
        
        const newSeries =
          monthlyData?.length === 1 && exists
            ? prev.series.map((element) => {
                let iSessions = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                if (element?.name === seriesData[0]?.name) {
                  iSessions = seriesData[0]?.data;
                }
                return {
                  ...element,
                  data: iSessions,
                };
              })
            : seriesData;
        return {
          ...prev,
          grid: {
            ...prev.grid,
            bottom: adjustedBottomPadding,
          },
          legend: {
            ...prev.legend,
            data: legendData,
          },
          series: newSeries,
        };
      });
    } catch (error) {
      logger.log(error);
    }
  };

  const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from(
      { length: 5 },
      (_, index) => currentYear - index
    );
    setYearList(yearList);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    generateYearList();
  }, []);

  useEffect(() => {
    if (yearList.length > 0) {
      setIsInitial(true);
      setSelectedYear(yearList[0]);
    }
  }, [yearList]);

  useEffect(() => {
    if (selectedYear) {
      if (isInitial) {
        // set time-out
        const timeout = setTimeout(() => {
          getMonthlyDowntime();
        }, 600);
        return () => clearTimeout(timeout);
      } else {
        getMonthlyDowntime();
      }
      setIsInitial(false);
      return () => cancelRequest();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (props?.chargePointCode) {
      const timeout = setTimeout(() => {
        if (selectedYear) {
          getMonthlyDowntime();
        }
      }, 600);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    }
  }, [props?.chargePointCode, props?.refresh]);

  return (
    <Card
      justifyContent="space-between"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex
        align="center"
        justifyContent="space-between"
        w="100%"
        ps="0px"
        pe="20px"
        pt="5px"
      >
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Monthly Downtime Report
        </Text>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          size="md"
          width="100px"
        >
          {yearList.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Box minH="260px" minW="95%" mt="auto" ml="auto" mr="auto">
          <ReactECharts ref={chartRef} option={chartOptions} />
        </Box>
      </Flex>
    </Card>
  );
};
export default MonthlyDowntimeReport;
