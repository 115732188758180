import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Switch,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Map, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useAxios from "axios-hooks";
import * as yup from "yup";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "components/card/Card";
import SearchLocation from "../components/SearchLocation";
import { loggedInUserState } from "atoms/loggedInUserState";
import { toastState } from "atoms/toastState";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import { onlyAdmins } from "utils/accessLevels";
import { onlyUAdmins } from "utils/accessLevels";

const token =
  "pk.eyJ1IjoiaGFyZXNocCIsImEiOiJjbGFlNjViMjUwNnJyM3dvNWp2dDg5aWh3In0.96qsX4fpBmX7yB9FRVejhQ";

const formFields = [
  {
    label: "Latitude",
    placeholder: "Latitude",
    type: "input",
    name: "Latitude",
  },
  {
    label: "Longitude",
    placeholder: "Longitude",
    type: "input",
    name: "Longitude",
  },
];

const Location = (props) => {
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const setToast = useSetRecoilState(toastState);
  const { id, getStation, data } = props;
  console.log("data", data);
  const [coordinates, setCoordiantes] = useState({
    Latitude: 17.723491627909628,
    Longitude: 83.30958857803418,
  });

  const [viewport, setViewport] = useState({
    height: "100%",
    width: "100%",
    longitude: 83.30958857803418,
    latitude: 17.723491627909628,
    zoom: 15,
  });
  const [isLive, setIsLive] = useState(data?.IsLive || false);
  const [isTesting, setIsTesting] = useState(data?.IsTesting || false);
  const [isVisible, setIsVisible] = useState(data?.IsVisible || false);
  const [isOcpiVisible, setIsOcpiVisible] = useState(
    data?.IsOcpiVisible || false
  );

  //axios hook
  const [{ data: updatedData, loading, error }, execute] = useAxios(
    {
      method: "PATCH",
      url: `/api/chargingStation/update/${id}`,
    },
    { manual: true }
  );
  //update schema
  const updateSchema = yup.object().shape({
    Latitude: yup
      .number()
      .min(-90, "Latitude must be greater than or equal to -90")
      .max(90, "Latitude must be less than or equal to 90")
      .required("Latitude is required"),
    Longitude: yup
      .number()
      .min(-180, "Longitude must be greater than or equal to -180")
      .max(180, "Longitude must be less than or equal to 180")
      .required("Longitude is required"),
    isLive: yup.boolean(),
    isTesting: yup.boolean(),
    isVisible: yup.boolean(),
    isOcpiVisible: yup.boolean(),
  });

  // form  validation
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(updateSchema),
  });

  const updateHandler = async () => {
    const formValues = getValues();
    let updateData = {};
    if (formValues.Latitude !== data?.Latitude) {
      updateData["Latitude"] = parseFloat(formValues.Latitude);
    }
    if (formValues.Longitude !== data?.Longitude) {
      updateData["Longitude"] = parseFloat(formValues.Longitude);
    }
    if (data?.isLive !== isLive) {
      updateData["IsLive"] = isLive;
    }

    if (data?.isTesting !== isTesting) {
      updateData["IsTesting"] = isTesting;
    }

    if (data?.isVisible !== isVisible) {
      updateData["IsVisible"] = isVisible;
    }

    if (data?.isOcpiVisible !== isOcpiVisible) {
      updateData["IsOcpiVisible"] = isOcpiVisible;
    }

    if (Object.keys(updateData).length > 0) {
      try {
        const response = await execute({ data: updateData });
        setToast({
          isOpen: true,
          title: "Location Updated successfully",
          status: "success",
        });
        getStation();
      } catch (error) {
        const err = error?.response?.data?.error;
        setToast({
          isOpen: true,
          title: "Error occured when updating location",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Nothing new to update",
        status: "warning",
        position: "top",
      });
    }
  };

  const clickHandler = (event) => {
    const { lng, lat } = event.lngLat;
    setCoordiantes((prev) => {
      return { ...prev, Longitude: lng, Latitude: lat };
    });
    setViewport((prev) => {
      return { ...prev, longitude: lng, latitude: lat };
    });
    setValue("Latitude", lat);
    setValue("Longitude", lng);
  };

  const moveHandler = (evt) => {
    setViewport(evt.viewState);
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    if (
      !isNaN(value) &&
      ((name === "Latitude" && value <= 90 && value >= -90) ||
        (name === "Longitude" && value <= 180 && value >= -180))
    ) {
      setCoordiantes((prev) => {
        return { ...prev, [name]: value };
      });
      let LName = name.toLowerCase();
      setViewport((prev) => {
        return { ...prev, [LName]: value };
      });
    }
  };

  const searchResultsHandler = (data) => {
    setViewport((prev) => {
      return { ...prev, latitude: data.Latitude, longitude: data.Longitude };
    });
    setCoordiantes({ Latitude: data.Latitude, Longitude: data.Longitude });
    setValue("Latitude", data.Latitude);
    setValue("Longitude", data.Longitude);
  };

  useEffect(() => {
    if (data?.Latitude && data?.Longitude) {
      setViewport((prev) => {
        return { ...prev, latitude: data.Latitude, longitude: data.Longitude };
      });
      setCoordiantes({ Latitude: data.Latitude, Longitude: data.Longitude });
      setValue("Latitude", data.Latitude);
      setValue("Longitude", data.Longitude);
      setIsLive(data?.IsLive || false);
      setIsTesting(data?.IsTesting || false);
      setIsVisible(data?.IsVisible || false);
      setIsOcpiVisible(data?.IsOcpiVisible || false);
    }
  }, [data]);

  return (
    <Card>
      <Grid
        templateAreas={`"map search"
      "form search"`}
        gap={3}
        gridTemplateColumns={"60% 40%"}
      >
        <GridItem area={"search"}>
          <SearchLocation setLocation={(item) => searchResultsHandler(item)} />
        </GridItem>
        <GridItem area={"map"}>
          <Card
            mt="10px"
            width="100%"
            height="400px"
            background="transparent"
            borderWidth="1px"
            padding={0}
            _disabled={true}
          >
            <Map
              {...viewport}
              mapboxAccessToken={token}
              // initialViewState={viewport}
              onMove={moveHandler}
              // onViewportChange={(viewPort) =>
              //   logger.log("printing viewport data : ", viewPort)
              // }
              mapStyle="mapbox://styles/mapbox/streets-v12"
              onClick={(e) => clickHandler(e)}
            >
              <Marker
                latitude={coordinates.Latitude}
                longitude={coordinates.Longitude}
                offsetLeft={-3.5 * viewport.zoom}
                offsetTop={-7 * viewport.zoom}
              ></Marker>
            </Map>
          </Card>
        </GridItem>
        <GridItem area={"form"}>
          <form>
            <Flex flexDirection="row" mt="20px">
              {formFields.map((item, index) => (
                <Controller
                  key={index}
                  name={item.name}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      isRequired
                      flexDirection="row"
                      mr="10px"
                      isInvalid={errors[item.name]}
                    >
                      <FormLabel fontSize="sm">{item.label}</FormLabel>
                      <Input
                        {...field}
                        fontSize="sm"
                        placeholder={item.placeholder}
                        type={item.type}
                        isRequired
                        onChange={(event) => {
                          field.onChange(event);
                          inputChangeHandler(event);
                        }}
                      />
                      <FormErrorMessage mb={"20px"}>
                        {errors[item.name] && errors[item.name].message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              ))}
            </Flex>
            <RoleBasedRender roles={onlyUAdmins}>
              <Flex flexDirection={"row"} align={"center"} justify={"center"}>
                <FormControl
                  display="flex"
                  alignItems="center"
                  mt="20px"
                  gap={2}
                >
                  <Checkbox
                    id="isLive"
                    isChecked={isLive}
                    onChange={() => setIsLive((prev) => !prev)}
                  />
                  <FormLabel mb="0">Live</FormLabel>
                </FormControl>
                <FormControl
                  display="flex"
                  alignItems="center"
                  mt="20px"
                  gap={2}
                >
                  <Checkbox
                    id="isTesting"
                    isChecked={isTesting}
                    onChange={() => setIsTesting((prev) => !prev)}
                  />
                  <FormLabel mb="0">Testing</FormLabel>
                </FormControl>
                <FormControl
                  display="flex"
                  alignItems="center"
                  mt="20px"
                  gap={2}
                >
                  <Checkbox
                    id="isVisible"
                    isChecked={isVisible}
                    onChange={() => setIsVisible((prev) => !prev)}
                  />
                  <FormLabel mb="0">UrzzaGo Visibility</FormLabel>
                </FormControl>
                <FormControl
                  display="flex"
                  alignItems="center"
                  mt="20px"
                  gap={2}
                >
                  <Checkbox
                    id="isOcpiVisible"
                    isChecked={isOcpiVisible}
                    onChange={() => setIsOcpiVisible((prev) => !prev)}
                  />
                  <FormLabel mb="0">OCPI Visibility</FormLabel>
                </FormControl>
              </Flex>
            </RoleBasedRender>
            <RoleBasedRender roles={onlyAdmins}>
              <Flex flexDirection={"row"} align={"center"} justify={"center"}>
                <Button
                  bgColor="#f57d36"
                  _hover={{ bgColor: "blue" }}
                  color="white"
                  mt="20px"
                  onClick={handleSubmit(updateHandler)}
                >
                  Save
                </Button>
              </Flex>
            </RoleBasedRender>
          </form>
        </GridItem>
      </Grid>
    </Card>
  );
};

export default Location;
