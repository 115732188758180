// Chakra imports
import { Box, Flex, Text, useColorModeValue, Select } from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { format, subMonths } from "date-fns";

// Custom components
import ReactECharts from "echarts-for-react";
import { weeklyTrendUnits } from "variables/charts";
import Card from "components/card/Card.js";
import logger from "infra/logger";

const WeeklyTrendUnits = (props) => {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [monthLists, setMonthLists] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isInitial, setIsInitial] = useState(false);
  const [updatedOption, setUpdatedOption] = useState(weeklyTrendUnits);

  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=month_units_weekly_trend&cp=${
        props?.chargePointCode == "all" ? "" : props?.chargePointCode
      }&year=${selectedDate?.year}&month=${selectedDate?.monthNum + 1}`,
    },
    { manual: true }
  );

  const getWeeklyUnits = async () => {
    try {
      const response = await execute();

      const responseData = response.data.data;

      const xAxisData = responseData.map((item) => `Week ${item.week_number}`);
      const yAxisData = responseData.map((item) => item.total_units);

      // Update weeklyTrendRevenueBar dynamically

      setUpdatedOption((prev) => {
        return {
          ...prev,
          xAxis: {
            ...prev.xAxis,
            data: xAxisData,
          },
          series: [
            {
              ...prev.series[0],
              data: yAxisData,
            },
          ],
        };
      });
    } catch (error) {
      logger.log("Error in getWeeklySessions:", error);
    }
  };

  const generateMonthList = () => {
    const currentDate = new Date();
    const currentMonthYear = {
      month: format(currentDate, "MMM"),
      year: format(currentDate, "yyyy"),
      monthNum: currentDate.getMonth(),
    };
    const monthList = [currentMonthYear];
    for (let i = 1; i < 12; i++) {
      const previousMonth = subMonths(currentDate, i);
      monthList.push({
        month: format(previousMonth, "MMM"),
        year: format(previousMonth, "yyyy"),
        monthNum: previousMonth.getMonth(),
      });
    }

    setMonthLists(monthList.reverse());
  };

  const monthChangeHandler = (event) => {
    const data = JSON.parse(event.target.value);
    const index = monthLists.findIndex((ele) => {
      return ele.month === data.month && ele.year === data.year;
    });
    setSelectedDate(monthLists[index]);
  };

  useEffect(() => {
    generateMonthList();
  }, []);

  useEffect(() => {
    if (monthLists.length > 0) {
      const currentDate = new Date();
      const currentMonth = format(currentDate, "MMM");
      const currentYear = format(currentDate, "yyyy");
      const index = monthLists.findIndex((ele) => {
        return ele.month === currentMonth && ele.year === currentYear;
      });
      setIsInitial(true);
      setSelectedDate(monthLists[index]);
    }
  }, [monthLists]);

  useEffect(() => {
    if (selectedDate) {
      if (isInitial) {
        // set time-out
        const timeout = setTimeout(() => {
          getWeeklyUnits();
        }, 600);
        return () => clearTimeout(timeout);
      } else {
        getWeeklyUnits();
      }
      setIsInitial(false);
      return () => cancelRequest();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (props?.chargePointCode) {
      const timeout = setTimeout(() => {
        if (selectedDate) {
          getWeeklyUnits();
        }
      }, 600);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    }
  }, [props?.chargePointCode, props?.refresh]);

  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex
        align="center"
        justifyContent="space-between"
        w="100%"
        px="15px"
        py="10px"
      >
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Weekly Units
        </Text>
        <Select
          value={JSON.stringify(selectedDate)}
          onChange={(e) => monthChangeHandler(e)}
          size="md"
          width="150px"
        >
          {monthLists.map((weeklyTrendUnits, index) => (
            <option key={index} value={JSON.stringify(weeklyTrendUnits)}>
              {weeklyTrendUnits.month}, {weeklyTrendUnits.year}
            </option>
          ))}
        </Select>
      </Flex>

      <Box minH="260px" minW="75%" mt="auto">
        <ReactECharts option={updatedOption} />
      </Box>
    </Card>
  );
};
export default WeeklyTrendUnits;
