// Chakra imports
import { Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import useAxios from "axios-hooks";

// Custom components
import Card from "components/card/Card.js";
import { chargingSessionsSplitByChargingStations } from "variables/charts";
import logger from "infra/logger";

export default function NCSSCS(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [selectedYear, setSelectedYear] = useState(null);
  const [chartOptions, setChartOptions] = useState(
    chargingSessionsSplitByChargingStations
  );
  const [sessionData, setSessionData] = useState([]);
  const [isInitial, setIsInitial] = useState(false);
  const [yearList, setYearList] = useState([]);

  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=stations_sessions_yearly_trend&cp=${
        props?.chargePointCode == "all" ? "" : props?.chargePointCode
      }&year=${selectedYear}`,
    },
    { manual: true }
  );

  const getSessionPerStation = async () => {
    try {
      const response = await execute();
      const sessionData = response?.data?.data;
      setChartOptions((prev) => {
        return {
          ...prev,
          legend: {
            ...prev.legend,
            data: sessionData?.map((item) => item.station_name),
          },
          series: [
            {
              ...prev.series[0],
              data: sessionData?.map((item) => ({
                value: item.total_sessions,
                name: item.station_name,
              })),
            },
          ],
        };
      });
      setSessionData(sessionData);
    } catch (error) {
      logger.log(error);
    }
  };

  const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from(
      { length: 5 },
      (_, index) => currentYear - index
    );
    setYearList(yearList);
  };

  const handleYearChange = async (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    generateYearList();
  }, []);

  useEffect(() => {
    if (yearList.length > 0) {
      setIsInitial(true);
      setSelectedYear(yearList[0]);
    }
  }, [yearList]);

  useEffect(() => {
    if (selectedYear) {
      if (isInitial) {
        // set time-out
        const timeout = setTimeout(() => {
          getSessionPerStation();
        }, 1100);
        return () => clearTimeout(timeout);
      } else {
        getSessionPerStation();
      }
      setIsInitial(false);
      return () => cancelRequest();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (props?.chargePointCode) {
      const timeout = setTimeout(() => {
        if (selectedYear) {
          getSessionPerStation();
        }
      }, 1100);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    }
  }, [props?.chargePointCode, props?.refresh]);

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          No. Of Charging Sessions split by Charging Stations
        </Text>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          size="md"
          width="100px"
        >
          {yearList.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Flex>
      <ReactECharts option={chartOptions} />
    </Card>
  );
}
