import useAxios from "axios-hooks";
import React, { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Box, Flex, Text, useColorModeValue, Select } from "@chakra-ui/react";
import * as echarts from "echarts";

// Custom components
import { monthlyTrendchargingSessionsBar } from "variables/charts";
import Card from "components/card/Card.js";
import logger from "infra/logger";

const MonthlyTrendChargingSessions = (props) => {
  const { ...rest } = props;
  const chartRef = useRef(null);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [chartOptions, setChartOptions] = useState(
    monthlyTrendchargingSessionsBar
  );
  const [yearList, setYearList] = useState([]);
  const [isInitial, setIsInitial] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=year_sessions_monthly_trend&cp=${
        props?.chargePointCode == "all" ? "" : props?.chargePointCode
      }&year=${selectedYear}`,
    },
    { manual: true }
  );

  const getMonthlySessions = async () => {
    try {
      const response = await execute();
      const monthlyData = response?.data?.data;
      const legendData = monthlyData?.map((ele) => {
        return ele.station_name;
      });
      const seriesData = monthlyData?.map((item) => {
        const itemSessions = item.sessions.map((ele) => {
          return ele.total_sessions;
        });
        return {
          name: item.station_name,
          data: itemSessions,
          type: "bar",
          smooth: true,
          barWidth: 20,
          stack: "stacked",
        };
      });
      const adjustedBottomPadding = Math.max(
        80,
        Math.ceil(legendData.length / 5) * 30
      );
      setChartOptions((prev) => {
        const newSeries =
          monthlyData?.length === 1
            ? prev.series.map((element) => {
                let iSessions = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                if (element?.name === seriesData[0]?.name) {
                  iSessions = seriesData[0]?.data;
                }
                return {
                  ...element,
                  data: iSessions,
                };
              })
            : seriesData;
        return {
          ...prev,
          grid: {
            ...prev.grid,
            bottom: adjustedBottomPadding,
          },
          legend: {
            ...prev.legend,
            data: legendData,
          },
          series: newSeries,
        };
      });
    } catch (error) {
      logger.log(error);
    }
  };

  const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from(
      { length: 5 },
      (_, index) => currentYear - index
    );
    setYearList(yearList);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    generateYearList();
  }, []);

  useEffect(() => {
    if (yearList.length > 0) {
      setIsInitial(true);
      setSelectedYear(yearList[0]);
    }
  }, [yearList]);

  useEffect(() => {
    if (selectedYear) {
      if (isInitial) {
        // set time-out
        const timeout = setTimeout(() => {
          getMonthlySessions();
        }, 800);
        return () => clearTimeout(timeout);
      } else {
        getMonthlySessions();
      }
      setIsInitial(false);
      return () => cancelRequest();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (props?.chargePointCode) {
      const timeout = setTimeout(() => {
        if (selectedYear) {
          getMonthlySessions();
        }
      }, 800);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    }
  }, [props?.chargePointCode, props?.refresh]);

  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex
        align="center"
        justifyContent="space-between"
        w="100%"
        px="15px"
        py="10px"
      >
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Monthly Sessions
        </Text>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          size="md"
          width="100px"
        >
          {yearList.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Flex>

      <Box
        minH={{ base: "200px", md: "350px" }}
        minW={{ base: "100%", md: "90%" }}
        mt="auto"
        pt={{ base: 5, md: 50 }}
        pb={{ base: 10, md: 20 }}
      >
        <ReactECharts ref={chartRef} option={chartOptions} />
      </Box>
    </Card>
  );
};
export default MonthlyTrendChargingSessions;
