import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {FaPlus} from "react-icons/fa";
import {useRecoilState, useRecoilValue} from "recoil";
import useAxios from "axios-hooks";
import {useLocation, useNavigate} from "react-router-dom";

import {createChargingPointDrawerState} from "atoms/createChargingPointDrawerState";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import ChargePoint from "../tab_panels/ChargePoint";
import CreateChargePointDrawer from "../components/CreateChargePointDrawer";
import logger from "infra/logger";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import Card from "components/card/Card";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";

const ChargePointTabs = ({id}) => {
  const [chargePoints, setChargePoints] = useState([]);
  const [drawerState, setDrawerState] = useRecoilState(
    createChargingPointDrawerState
  );
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit = accessLevels[role]?.accessLevel <= 2;
  const [{data, loading, error}, getChargePoint] = useAxios(
    {method: "GET", url: `/api/chargePoint/forStation/${id}`},
    {manual: true}
  );

  const getChargePoints = async () => {
    try {
      const response = await getChargePoint();
      setChargePoints(response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };

  const refreshHandler = async () => {
    setDrawerState(null);
    await getChargePoints();
  };

  const chargePointTabs = chargePoints.map((ele, index) => {
    return (
      <Tab mx={1} w={120} key={index}>
        {ele.Code}
      </Tab>
    );
  });

  const chargePointPanels = chargePoints.map((ele, index) => {
    return (
      <TabPanel key={index}>
        <ChargePoint getChargePoints={getChargePoints} data={ele} />
      </TabPanel>
    );
  });

  // Syncing the URL with the selected charge point tab
  const handleTabChange = (index) => {
    const selectedChargePoint = chargePoints[index];
    setCurrentTab(index);
    navigate(`?chargePoint=${selectedChargePoint.Code}`, { replace: true });
  };

  useEffect(() => {
    getChargePoints();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const chargerId = searchParams.get("chargePoint");
    if (chargePoints.length > 0 && chargerId) {
      const index = chargePoints.findIndex(
        (chargePoint) => chargePoint.Code === chargerId
      );

      if (index !== -1) {
        setCurrentTab(index);
      } else {
        navigate(-1); // If chargerId in URL doesn't match, go back.
      }
    }
  }, [chargePoints, location.search, navigate]);

  return !loading ? (
    <Card minH="60vh" height="100%">
      {chargePoints.length == 0 && (
        <Flex
          padding="36"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <RoleBasedRender roles={onlyAdmins}>
            <Button
              leftIcon={<FaPlus />}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => setDrawerState({isOpen: true})}
              disabled={!canEdit}
            >
              Add Charge Point
            </Button>
          </RoleBasedRender>
        </Flex>
      )}
      {drawerState?.isOpen && (
        <CreateChargePointDrawer
          refresh={getChargePoints}
          id={id}
          update={refreshHandler}
        />
      )}

      {chargePoints.length > 0 && (
        <Tabs
          index={currentTab}
          onChange={handleTabChange} // Set the tab change handler
          isLazy
          isFitted
          variant="soft-rounded"
          colorScheme="orange"
        >
          <Flex
            flexDirection="row"
            alignItems="start"
            justifyContent="space-between"
          >
            <TabList>{chargePointTabs}</TabList>
            <RoleBasedRender roles={onlyAdmins}>
              <Button
                leftIcon={<FaPlus />}
                colorScheme="whatsapp"
                variant="solid"
                onClick={() => setDrawerState({isOpen: true})}
                disabled={!canEdit}
              >
                Add Charge Point
              </Button>
            </RoleBasedRender>
          </Flex>

          <TabPanels>{chargePointPanels}</TabPanels>
        </Tabs>
      )}
    </Card>
  ) : (
    <LoadingSpinner />
  );
};

export default ChargePointTabs;
