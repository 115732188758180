import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { Controller, get, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { toastState } from "atoms/toastState";
import { getFirstDateOfMonth } from "utils/utils";
import { getCurrentDate } from "utils/utils";
import logger from "infra/logger";
import { dynamicStatsSelectOptions } from "../variables/dynamicStatsSelectOptions";
import TotalDowntimeReport from "./TotalDowntimeReport";
import SeparatorWithTitle from "components/card/SeparatorWithTitle";
import DailyDowntimeReport from "./DailyDowntimeReport";
import TotalDowntime from "./TotalDowntime";
import WeeklyDowntimeReport from "./WeeklyDowntimeReport";
import MonthlyDowntimeReport from "./MonthlyDowntimeReport";

const PerformanceReportTab = () => {
  const [points, setPoints] = useState([]);
  const [selectedChargePoint, setSelectedChargePoint] = useState("all");
  const [selectedFrom, setSelectedFrom] = useState(getFirstDateOfMonth());
  const [selectedTo, setSelectedTo] = useState(getCurrentDate());
  const [refresh, setRefresh] = useState(false);

  const setToast = useSetRecoilState(toastState);
  const [
    {
      data: chargePointsData,
      loading: chargePointsLoading,
      error: chargePointsError,
    },
    getPoints,
    cancelPointsRequest,
  ] = useAxios(
    {
      method: "GET",
      url: `/api/chargePoint`,
    },
    { manual: true }
  );

  const [
    { data, loading, error },
    getTotalDowntime,
    cancelTotalDowntimeRequest,
  ] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=downtime_report&cp=${
        selectedChargePoint === "all" ? "" : selectedChargePoint
      }&startTime=${selectedFrom}&endTime=${selectedTo}`,
    },
    { manual: true }
  );

  const validationSchema = yup.object().shape({
    From: yup.date().required("From is required"),
    To: yup.date().required("To is required"),
    ChargePoint: yup.string().required("chargePoint is Mandatory"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getChargePoints = async () => {
    try {
      const response = await getPoints();
      setPoints(response?.data?.data);
    } catch (error) {
      logger.log(error);
    }
  };
  const pointOptions = points.map((ele, index) => {
    return (
      <option key={index} value={ele.Code}>
        {ele.Code} - {ele.Name}
      </option>
    );
  });

  const getTotalDowntimeData = async () => {
    try {
      await getTotalDowntime();
    } catch (error) {
      logger.log(error);
    }
  };

  const getOrUpdateStats = () => {
    const formValues = getValues();

    setSelectedChargePoint(formValues.ChargePoint || "all");
    setSelectedFrom(formValues.From || getFirstDateOfMonth());
    setSelectedTo(formValues.To || getCurrentDate());
    setRefresh(!refresh);

    console.log("formValues", formValues);
  };

  useEffect(() => {
    getChargePoints();
    getOrUpdateStats();
    return () => {
      cancelPointsRequest();
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getTotalDowntimeData();
    }, 100);
    return () => {
      clearTimeout(timeout);
      cancelTotalDowntimeRequest();
    };
  }, [refresh]);

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#e8edf7"
        padding={5}
        borderRadius={8}
        mb={5}
        overflow="hidden"
      >
        <Flex
          direction="row"
          alignItems="flex-end"
          justifyContent="center"
          gap={5}
          mb={10}
          flexWrap="wrap"
        >
          {dynamicStatsSelectOptions.map((item) => {
            return (
              <Controller
                key={item.name}
                name={item.name}
                control={control}
                defaultValue={item.defaultValue}
                render={({ field }) => (
                  <FormControl
                    isRequired
                    flexDirection="row"
                    isInvalid={errors[item.name]}
                    width="unset"
                  >
                    <FormLabel fontSize={20}>{item.label}</FormLabel>
                    {item.type === "datetime-local" && (
                      <Input
                        {...field}
                        borderColor={"blackAlpha.500"}
                        background="white"
                        focusBorderColor="#f57d36"
                        fontSize="sm"
                        width="400px"
                        height="50px"
                        type={item.type}
                        isRequired
                      />
                    )}
                    {item.type === "select" && (
                      <Select
                        {...field}
                        borderColor={"blackAlpha.500"}
                        background="white"
                        focusBorderColor="#f57d36"
                        fontSize="sm"
                        width="400px"
                        height="50px"
                        type={item.type}
                        isRequired
                      >
                        <option value="all">All Chargers</option>
                        {pointOptions}
                      </Select>
                    )}
                    <FormHelperText color={"#f57d36"}>
                      {item.helper}
                    </FormHelperText>
                    <FormErrorMessage mb={"20px"}>
                      {errors[item.name] && errors[item.name].message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            );
          })}
          <Button
            colorScheme="green"
            borderRadius={5}
            mb={2.5}
            onClick={handleSubmit(getOrUpdateStats)}
            isLoading={loading}
          >
            Get Data
          </Button>
        </Flex>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="center"
          alignContent={"center"}
          gap={5}
          borderWidth={1}
        >
          <TotalDowntime data={data?.data} />
        </Flex>
      </Flex>
      <SeparatorWithTitle title="Downtime Report Trends" />
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalDowntimeReport
          ChargePoint={selectedChargePoint}
          From={selectedFrom}
          To={selectedTo}
          refresh={refresh}
        />
        <DailyDowntimeReport
          chargePointCode={selectedChargePoint}
          refresh={refresh}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <WeeklyDowntimeReport
          chargePointCode={selectedChargePoint}
          refresh={refresh}
        />
        <MonthlyDowntimeReport
          chargePointCode={selectedChargePoint}
          refresh={refresh}
        />
      </SimpleGrid>
    </>
  );
};

export default PerformanceReportTab;
